import Vue from 'vue'
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import responseMixin from '@/mixins/responseMixin'
import canonicalMixin from '@/mixins/canonicalMixin'
import animateScrollTo from '@/utils/animateScrollTo'
import { formatDate, isBefore } from '@/utils/date'
import { USER_TYPE_CUSTOMER_PERSON } from '@/constants/user/roles'
import Skill from '@/models-ts/Skill'
import { BROWSE_JOBS, BROWSE_JOBS_BY_SKILL } from '@/constants/routes'
import PrefferedCurrency from '@/partials/PrefferedCurrency/PrefferedCurrency.vue'
import JobsSection from './JobsSection/JobsSection.vue'
// ToDo: move to partials
import SocialMedia from '../ProfileSettings/CustomerProfile/SocialMediaSection/SocialMediaSection.vue'
import VacanciesSection from './VacanciesSection/VacanciesSection.vue'
import skillableMixin from '@/mixins/skillableMixin'
import UserVerificationBadge from '@/partials/StatusBadges/UserVerificationBadge/UserVerificationBadge.vue'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, canonicalMixin, skillableMixin],
  components: {
    PrefferedCurrency,
    JobsSection,
    SocialMedia,
    VacanciesSection,
    UserVerificationBadge,
  },
  data () {
    return {
      BROWSE_JOBS,
    }
  },
  computed: {
    ...mapState('countries', {
      countriesIsLoaded: (state: any) => state.list.isLoaded,
      countriesIsLoading: (state: any) => state.list.isLoading,
      countries: (state: any) => state.list.value,
    }),
    ...mapState<RootState>({
      prefetched: (state: any) => state.customerDetails.prefetched,
      isLoggedIn: (state: RootState) => state.app.authorized,
    }),
    ...mapGetters({
      customer: 'customerDetails/customer',
      isLoading: 'customerDetails/isLoading',
      jobs: 'customerDetails/jobs',
      vacancies: 'customerDetails/vacancies',
      getSkillRoots: 'skills/getRoots',
    }),
    isNotBanned () {
      return !this.customer.bannedAt || isBefore(this.customer.bannedAt, Date.now())
    },
    isPerson () {
      return this.customer.type === USER_TYPE_CUSTOMER_PERSON
    },
    website () {
      const website = this.isPerson
        ? this.customer.individualInfo.website
        : this.customer.companyInfo.website
      if (website) {
        try {
          const url = new URL(website)
          return { link: website, domain: url.hostname }
        } catch (e) {
          if (!website.startsWith('http')) {
            return { link: `http://${website}`, domain: website }
          }
        }
      }
    },
    avatar () {
      return this.isPerson
        ? this.customer.individualInfo.avatar
        : this.customer.companyInfo.avatar
    },
    locations () {
      if (this.isPerson) {
        if (!this.countriesIsLoaded) {
          return null
        }
        const country = (this.countries || []).find((c: any) => c.id === this.customer.internalCountryId)
        return country?.name
      }
      return this.customer.companyInfo.countries.length > 0
        ? this.customer.companyInfo.countries.map((c: any) => c.name).join(', ')
        : null
    },
    description () {
      return this.isPerson ? this.customer.individualInfo.description : this.customer.companyInfo.description
    },
    tagSkills () {
      return this.customer.skills.map((skill: Skill) => {
        if (skill.url) {
          return {
            text: skill.name,
            link: { name: BROWSE_JOBS_BY_SKILL, params: { skill: skill.url } }
          }
        }
        return { text: skill.name }
      })
    },
    hasJobs () {
      return this.jobs?.length
    },
    hasVacancies () {
      return this.vacancies?.length
    },
  },
  watch: {
    '$route.params': {
      handler () {
        this.loadCustomer()
      }
    }
  },
  async prefetch () {
    try {
      if (process.server) {
        await Promise.all([this.load(this.$route.params.id), this.getCountries()])
        this.setPrefetched(true)
      }
    } catch (e) {
      if (ErrorMatcher.isGone(e) || ErrorMatcher.isNotFound(e)) {
        this.setNotFound(true)
      } else {
        console.error('Fetch customer details error:', e)
      }
    }
  },
  async created () {
    try {
      if (process.client) {
        if (!this.countriesIsLoaded) {
          await this.getCountries()
        }
        if (!this.prefetched) {
          await this.loadCustomer()
        } else {
          this.setPrefetched(false)
        }
      }
    } catch (e) {
      if (ErrorMatcher.isGone(e) || ErrorMatcher.isNotFound(e)) {
        this.setNotFound(true)
      } else {
        this.parseError(e)
      }
    }
  },
  beforeDestroy () {
    this.clearCustomer()
  },
  methods: {
    ...mapMutations({
      setNotFound: 'app/setNotFound',
      setRedirect: 'app/setRedirect',
      setPrefetched: 'customerDetails/setPrefetched',
      clearCustomer: 'customerDetails/clearCustomer',
    }),
    ...mapActions({
      load: 'customerDetails/load',
      getCountries: 'countries/getCountries',
    }),
    async loadCustomer () {
      try {
        await this.load(this.$route.params.id)
        if (this.$route.hash) {
          const [, id] = this.$route.hash.split('#')
          if (id) {
            const el = document.getElementById(id)
            if (el) {
              animateScrollTo(document.getElementById(id))
            }
          }
        }
      } catch (e) {
        if (ErrorMatcher.isNotFound(e)) {
          this.setNotFound(true)
        } else {
          throw e
        }
      }
    }
  },
  metaInfo () {
    const meta = []
    if (this.customer) {
      if (!this.isNotBanned) {
        meta.push({
          vmid: 'robots',
          name: 'robots',
          content: 'noindex',
        })
      }
      meta.push({
        vmid: 'description',
        name: 'description',
        // eslint-disable-next-line max-len
        content: `Find jobs at "${this.customer.fullName}". We help clients and talents connect, providing efficient blockchain transactions and robust protections through smart contracts - wherever you're based`
      })
      meta.push({
        vmid: 'og:description',
        property: 'og:description',
        // eslint-disable-next-line max-len
        content: `Find jobs at "${this.customer.fullName}". We help clients and talents connect, providing efficient blockchain transactions and robust protections through smart contracts - wherever you're based`
      })
      meta.push({
        vmid: 'og:title',
        property: 'og:title',
        content: `Customer ${this.customer.fullName}`
      })
      if (!this.customer.isPretty) {
        meta.push({
          vmid: 'robots',
          name: 'robots',
          content: 'noindex', //
        })
      }
    }
    const jobCount = (this.jobs?.length || 0) + (this.vacancies?.length || 0)

    return {
      title: this.customer
        ? `${this.customer.fullName} Jobs & Careers - ${jobCount} new jobs ${formatDate(new Date(), 'MMM YYYY')}`
        : 'Loading',
      meta,
      link: [this.canonicalLink],
    }
  },
})
