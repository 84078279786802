import Vue from 'vue'
import { VACANCIES } from '@/constants/routes'
import VacancyCardNew from '@/partials/VacancyCardNew/VacancyCardNew.vue'
import { mapGetters, mapState } from 'vuex'

export default Vue.extend<any, any, any, any>({
  components: {
    VacancyCardNew
  },
  data () {
    return {
      VACANCIES,
      swiper: null,
    }
  },
  computed: {
    ...mapState({
      isLoaded: (state: any) => state.customerDetails.vacancies.isLoaded,
    }),
    ...mapGetters({
      vacancies: 'customerDetails/vacancies',
    }),
  },
  watch: {
    isLoaded: {
      handler () {
        if (process.client && this.isLoaded) {
          this.$nextTick(async () => {
            // @ts-ignore
            const Swiper = (await import(/* webpackChunkName: "swiper" */ 'swiper')).default
            const ref = 'jobsContainer'
            if (this.$refs[ref]) {
              const swiper = new Swiper(this.$refs[ref], {
                slidesPerView: 'auto',
                spaceBetween: 32,
                freeMode: true,
                navigation: {
                  nextEl: this.$refs[`${ref}NextBtn`].$el,
                  prevEl: this.$refs[`${ref}PrevBtn`].$el,
                },
              })
              swiper.allowTouchMove = false
              this.swiper = swiper
            }
          })
        }
      },
      immediate: true,
    },
  },
})
