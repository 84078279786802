import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import JobCard from '@/partials/JobCards/JobCard/JobCard.vue'
import { BROWSE_JOBS } from '@/constants/routes'
import { USER_TYPE_CUSTOMER_PERSON } from '@/constants/user/roles'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin],
  components: {
    JobCard,
  },
  props: {
  },
  data () {
    return {
      BROWSE_JOBS,
      swiper: null,
    }
  },
  computed: {
    ...mapState({
      isLoaded: (state: any) => state.customerDetails.jobs.isLoaded,
    }),
    ...mapGetters({
      customer: 'customerDetails/customer',
      jobs: 'customerDetails/jobs',
    }),
    isPerson () {
      return this.customer.type === USER_TYPE_CUSTOMER_PERSON
    },
  },
  watch: {
    isLoaded: {
      handler () {
        if (process.client && this.isLoaded) {
          this.$nextTick(async () => {
            // @ts-ignore
            const Swiper = (await import(/* webpackChunkName: "swiper" */ 'swiper')).default
            const ref = 'jobsContainer'
            if (this.$refs[ref]) {
              const swiper = new Swiper(this.$refs[ref], {
                slidesPerView: 'auto',
                spaceBetween: 32,
                freeMode: true,
                navigation: {
                  nextEl: this.$refs['jobsNextBtn']?.$el,
                  prevEl: this.$refs['jobsPrevBtn']?.$el,
                },
              })
              swiper.allowTouchMove = false
              this.swiper = swiper
            }
          })
        }
      },
      immediate: true,
    },
  },
})
